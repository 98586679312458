<template>
    <v-card class="pa-1">
        <h5 color="secondary">Price</h5>
        <v-row justify="space-between">
            <v-subheader color="secondary">${{min}}</v-subheader>
            <v-subheader color="secondary">${{max}}</v-subheader>
        </v-row>
        <v-range-slider
        v-model="prices"
        :min="min"
        :max="max"
        color="secondary"
        hide-details
        class="align-center"
        thumb-label
        @change="filterate()"
        >
        </v-range-slider>
        <v-divider></v-divider>
        <h5 color="secondary">Categories</h5>
        <v-btn text small color="blue" @click="allCars('cat')">All Categories <v-icon right>mdi-chevron-right</v-icon></v-btn>
        <v-btn active-class="active" text v-for="cat in categories" :key="cat.category" class="my-5" block @click="selectedCat = cat.category; filterate();">
            <v-row justify="space-between">
                <span class="caption primary--text">{{cat.category}}</span>
                <v-spacer></v-spacer>
                <div>
                     <span class="caption primary--text">{{cat.numberOfCars}} Cars</span>
                    <v-icon color="blue">mdi-chevron-right</v-icon>
                </div>
            </v-row>
        </v-btn>
        <v-divider></v-divider>
        <h5 color="secondary">Seats</h5>
        <v-btn text small color="blue" @click="allCars('seat')">All Seats <v-icon right>mdi-chevron-right</v-icon></v-btn>
        <v-btn text v-for="seat in seats" :key="seat.numberOfSeats" class="my-5" block @click="selectedSeat = seat.numberOfSeats; filterate();">
            <v-row justify="space-between">
                 <span class="caption primary--text">{{seat.numberOfSeats}} Seats /</span>
                <v-spacer></v-spacer>
                <div>
                     <span class="caption primary--text"> {{seat.numberOfCars}} Cars</span>
                    <v-icon color="blue">mdi-chevron-right</v-icon>
                </div>
            </v-row>
        </v-btn>
        <v-divider></v-divider>
        <h5 color="secondary">Vendors</h5>
        <v-btn text color="blue" @click="allCars('vendor')">All Vendors <v-icon right>mdi-chevron-right</v-icon></v-btn>
        <v-btn text v-for="vendor in vendors" :key="vendor.vendor" class="my-5" block @click="selectedVendor = vendor.vendor; filterate();">
            <v-row justify="space-between">
                 <span class="caption primary--text">{{vendor.vendor}} </span>
                <v-spacer></v-spacer>
                <div>
                     <span class="caption primary--text">{{vendor.numberOfCars}} Cars</span>
                    <v-icon color="blue">mdi-chevron-right</v-icon>
                </div>
            </v-row>
        </v-btn>
        <v-divider></v-divider>
        <v-btn text color="blue" @click="getAllCars()">All Cars <v-icon right>mdi-chevron-right</v-icon></v-btn>
    </v-card>
</template>

<script>
export default {
  data () {
    return {
      cars: [],
      prices: [],
      min: 0,
      max: 0,
      categories: [],
      seats: [],
      vendors: [],
      selectedCat: null,
      selectedVendor: null,
      selectedSeat: null
    }
  },
  methods: {
    filterate () {
      const filtered = []
      this.cars.forEach(car => {
        if (
          this.prices[0] <= car.fareInfo[car.fareInfo.length - 1].Amount && car.fareInfo[car.fareInfo.length - 1].Amount <= this.prices[1] &&
        car.carInfo.carCategory === (this.selectedCat ? this.selectedCat : car.carInfo.carCategory) &&
        car.carInfo.seats === ((this.selectedSeat || this.selectedSeat === 0) ? this.selectedSeat : car.carInfo.seats) &&
        car.vendorName === (this.selectedVendor ? this.selectedVendor : car.vendorName)
        ) {
          filtered.push(car)
        }
      })
      const criteria = {
        prices: this.prices,
        cat: this.selectedCat ? this.selectedCat : 'all categories',
        seats: this.selectedSeat ? this.selectedSeat : 'any number of',
        vendors: this.selectedVendor ? this.selectedVendor : 'any vendor'
      }
      this.$emit('changed', filtered, criteria)
    },
    allCars (type) {
      const filtered = []
      if (type === 'cat') {
        this.selectedCat = null
        this.cars.forEach(car => {
          if (this.prices[0] <= car.fareInfo[car.fareInfo.length - 1].Amount <= this.prices[1] && car.carInfo.carCategory === (this.selectedCat ? this.selectedCat : car.carInfo.carCategory)) {
            filtered.push(car)
          }
        })
        const criteria = {
          prices: this.prices,
          cat: 'all categories',
          seats: this.selectedSeat,
          vendors: this.selectedVendor
        }
        this.$emit('changed', filtered, criteria)
      } else if (type === 'seats') {
        this.selectedSeat = null
        this.cars.forEach(car => {
          if (this.prices[0] <= car.fareInfo[car.fareInfo.length - 1].Amount <= this.prices[1] && car.carInfo.seats === (this.selectedSeat ? this.selectedSeat : car.carInfo.seats)) {
            filtered.push(car)
          }
        })
        const criteria = {
          prices: this.prices,
          cat: this.selectedCat,
          seats: 'any number of',
          vendors: this.selectedVendor
        }
        this.$emit('changed', filtered, criteria)
      } else {
        this.selectedVendor = null
        this.cars.forEach(car => {
          if (this.prices[0] <= car.fareInfo[car.fareInfo.length - 1].Amount <= this.prices[1] && car.vendorName === (this.selectedVendor ? this.selectedVendor : car.vendorName)) {
            filtered.push(car)
          }
        })
        const criteria = {
          prices: this.prices,
          cat: this.selectedCat,
          seats: this.selectedSeat,
          vendors: 'all vendors'
        }
        this.$emit('changed', filtered, criteria)
      }
    },
    getAllCars () {
      const filtered = []
      this.cars.forEach(car => {
        filtered.push(car)
      })
      const criteria = null
      this.$emit('changed', filtered, criteria)
    }
  },
  created () {
    this.min = Math.floor(this.$store.state.cars.filterPrice.minPrice)
    this.max = Math.floor(this.$store.state.cars.filterPrice.maxPrice)
    this.prices = [this.min, this.max]
    this.categories = this.$store.state.cars.categories
    this.seats = this.$store.state.cars.seats
    this.vendors = this.$store.state.cars.vendors
    this.cars = [...this.$store.state.cars.cars]
  }
}
</script>
